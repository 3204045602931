import { createApp } from 'vue'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueYandexMetrika from 'vue-yandex-metrika' 

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'vuetify/styles';
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from 'vuetify';

const options = {
  color: "#7C57FC",
  failedColor: "#000",
  thickness: "3px", 
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
}

axios.defaults.baseURL = 'https://barselmedia.ru'
// axios.defaults.baseURL = 'https://barselmedia.ru'

const vuetify = createVuetify({
  directives,
  components,
});


let app = createApp(App);

if (process.env.NODE_ENV === "production") {
    app = app.use(VueYandexMetrika, {
        id: 93810436,
        router: router,
        env: process.env.NODE_ENV
    });
}

app.use(VueProgressBar, options).use(vuetify).use(store).use(router, axios).mount('#app')
