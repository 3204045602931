<template>
   <div class="player__video video_play">
      <p class="player__video_yers">{{ videoInfo.censored }}+</p>
      <video playsinline ref="videoPlayer" preload="metadata" @loadedmetadata="logDuration"
         class="video-js vjs-custom-skin">
      </video>
   </div>

   <div class="setting__quality" ref="list" v-if="videoInfo.video_kach">
      <span @click="showMenuList()"><img src="@/assets/img/settings.svg"></span>
      <ul class="menu_quality-video" v-if="menu" :class="{ 'one': videoInfo.video_kach.length == 1 }">
         <li v-for="list in videoInfo.video_kach"
            :class="{ 'active': kach_current == list.kach, 'one': videoInfo.video_kach.length == 1 }"
            @click="SetQuality(list.kach, list.link_video)">
            <a>{{ list.kach }}</a>
         </li>
      </ul>
   </div>
</template>
<style lang="scss">
@import '@/assets/css/MainComponent/main.css';

.marquee-wrapper {
   overflow: hidden;
   background-color: #0066C3
}

// .marquee-infinite {
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    gap: 30px;
//    -webkit-animation: marquee-infinite 10s linear infinite;
//    animation: marquee-infinite 10s linear infinite;
//    font-weight: 500;
//    font-size: 18px;
//    line-height: 100%;
//    height: 40px;
//    -webkit-box-align: center;
//    -ms-flex-align: center;
//    align-items: center;
//    color: #fff
// }


.runeline {
   height: 40px;
   color: white;
   font-size: 18px;
   font-weight: 500;
   line-height: 100%;
   background-color: #0066C3;
   overflow: hidden;
   display: flex;
   align-items: center;

   // margin: 0 auto;
   @media(max-width: 992px) {
      font-size: 14px;
      height: 32px;
   }
}

.marquee-0 {
   --space: 0rem;
   display: grid;
   align-content: center;
}

.marquee {
   --duration: v-bind(nduration);
   --gap: var(--space);
   display: flex;
   user-select: none;
   position: relative;
   max-width: 100vw;
}

.marquee:hover {
   z-index: 99;
}

.marquee__group {
   flex-shrink: 0;
   display: flex;
   align-items: center;
   justify-content: space-around;
   min-width: 100%;
   animation: scroll var(--duration) linear infinite;
   // gap: 56px;
}

.marquee:hover .marquee__group {
   // animation-play-state: paused;
}

@media (prefers-reduced-motion: reduce) {
   .marquee__group {
      animation-play-state: paused;
   }
}

.marquee__group span {
   margin: 7px;
}


@keyframes scroll {
   0% {
      transform: translateX(0);
   }

   100% {
      transform: translateX(calc(-100% - var(--gap)));
   }
}

@media(max-width: 768px) {
   .marquee-infinite {
      height: 28px;
   }

   .marquee-track {
      font-size: 12px;
   }

   .video-live_stroke {
      //display: none !important;
      //bottom: 20px;
   }

   .video_play {
      .video-js {
         overflow: hidden;
      }
   }
}
</style>
<script>
import axios from 'axios'
import moment from 'moment';
import videojs from 'video.js';


export default {
   name: 'MainComponent',
   data() {
      return {
         player: null,
         text: "",
         liveStroka: false,
         durationvideo: 0,

         errorIndex: 0,

         menu: false,
         kach_current: 720,
         nduration: '10s',
      }
   },
   components: {

   },
   props: {
      options: {
         type: Object,
         default: {}
      },
      detail: {
         type: Object,
         default: {},
      },
      curr_time: {
         type: Number,
         default: 0,
      },
      videoInfo: {
         type: Object,
         default: {},
      },
   },
   mounted() {
      // setTimeout(() => {
      //     this.addInDefault()
      //     this.sorting()
      // }, 510)
      setTimeout(() => {
         this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            console.log('player', this.options);

            this.player.controlBar.progressControl.show();
            // this.player.currentTime(this.curr_time)
            var ButtonComp = videojs.getComponent('Button');
            var notesBut = new ButtonComp(this.player, {
               clickHandler(event) {
                  this.player_.liveTracker.seekToLiveEdge();
               }
            });
            notesBut.addClass('addNotesBut')
            this.player.controlBar.addChild(notesBut, {}, 3)
            document.querySelector('.addNotesBut .vjs-icon-placeholder').innerHTML = '<p> В ЭФИРЕ </p>'
            document.querySelector('.addNotesBut').style = 'width: auto !important'
         });

         var Component = videojs.getComponent('Component');
         class LiveStroke extends Component {
            constructor(player, options) {
               Component.apply(this, arguments);

               if (options.text) {
                  this.createEl(options.text);
               }
            }

            // createEl(text) {
            //    const el = videojs.createEl('div');

            //    el.className = 'video-live_stroke';
            //    el.innerHTML =
            //       `<div class="marquee-wrapper">` +
            //       `<div class="marquee-infinite">` +
            //       `<div class="marquee-track">` +
            //       `<span>${this.options_.text}</span>` +
            //       `</div>` +
            //       `<div class="marquee-track">` +
            //       `<span>${this.options_.text}</span>` +
            //       `</div>` +
            //       `</div>` +
            //       `</div>`;

            //    return el;
            // },
            createEl(text) {
               const el = videojs.createEl('div');

               el.className = 'video-live_stroke';
               el.innerHTML =
               `
                           <div class="runeline">
               <div class="marquee-0">
                  <div class="marquee marquee-b2b">
                     <div class="marquee__group">
                        <div class="running-line__item">
                        
                           <span class="text14">
                              ${this.options_.text}
                           </span>
                        </div>
                        <div class="running-line__item">
                           <span class="text14">
                              ${this.options_.text}
                           </span>
                        </div>
                     </div>

                     <div aria-hidden="true" class="marquee__group">
                        <div class="running-line__item">
                           <span class="text14">
                              ${this.options_.text}
                           </span>
                        </div>
                        <div class="running-line__item">
                           <span class="text14">
                              ${this.options_.text}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
                           `;

               return
            }
         };

         // var ButtonComp = videojs.getComponent('Button');
         // var camp = new ButtonComp(this.player);
         // camp.addClass('setQuality')
         // this.player.controlBar.addChild(camp,{},10)

         // var qk = document.querySelector('.setQuality .vjs-icon-placeholder')
         // var list = this.$refs.list
         // qk.innerHTML = ''
         // qk.appendChild(list);
         // console.log(list)
         // document.querySelector('.setQuality').style = 'width: auto !important;'
         // document.querySelector('.setQuality .setting__quality').style = 'opacity: 1 !important;'
         // document.querySelector('.setQuality span.vjs-icon-placeholder').style = 'margin: 0px 10px 0px 0px;'
         // this.$emit('durvideo', this.player.duration())

         if (this.liveStroka) {
            videojs.registerComponent('LiveStroke', LiveStroke);
            this.player.addChild('LiveStroke', { text: this.text })
         }
      }, 500)

      // if (!this.isDesktopDevice) {
      //     setTimeout(() => {
      //         this.player.currentTime(this.curr_time)
      //         this.$emit('duration', this.$refs.videoPlayer.duration)
      //     }, 6000)
      // }

      // this.$refs.videoPlayer.onloadedmetadata = (e) => {
      //     console.log(e.target.duration)
      //     // this.$emit('duration', e.target.duration)
      //     // this.player.currentTime(this.curr_time)
      //     // if (this.player.paused()) {
      //     //     this.player.play()
      //     // }        
      // };


   },
   beforeDestroy() {
      if (this.player) {
         this.player.nuevo();
      }
   },
   computed: {
      isDesktopDevice() {
         if (window.innerWidth > 500) {
            return true
         } else {
            return false
         }
      }
   },
   methods: {
      addInDefault() {
         var ui = this.videoInfo.video_kach.find(item => item.kach === 720)
         if (ui == undefined || ui == 'undefined') {
            this.videoInfo.video_kach.push({ link_video: this.videoInfo.link_video, kach: 720 })
         }
      },
      sorting() {
         this.videoInfo.video_kach.sort((a, b) => (a.kach > b.kach ? -1 : 1));
      },
      showMenuList() {
         this.menu = !this.menu
      },
      SetQuality(kach, link) {
         this.kach_current = kach
         this.player.src({ src: link, type: 'video/mp4' })
         this.player.currentTime(this.curr_time)
         this.menu = false
      },
      logDuration() {
         this.$emit('duration', this.$refs.videoPlayer.duration)
         // this.player.currentTime(this.curr_time)
      },
      async LiveStroka() {
         await axios
            .get(`/api/v1/get/live-stroka/`)
            .then(response => {
               if (response.data.length >= 1) {
                  this.liveStroka = true
                  this.text = response.data[0].message
                  this.nduration = String(response.data[0].duration + 's')
               } else {
                  this.liveStroka = false
               }

               console.log(response.data, this.nduration)
            })
            .catch(error => {
               console.log(error)
            })

      },
   },
   watch: {
      // "player.currentTime"() {
      //     console.log(this.player, this.player.currentTime)
      // },
   },
   created() {
      this.LiveStroka()
   },
};
</script>